<template>
  <div class="phone-page">
    <div class="phone-left">
      <el-form id="form-box">
        <el-form-item>
          <el-input
            size="small"
            v-model="value"
            :placeholder="'请输入' + routerName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            style="width: 100%; border-radius: 16px"
            @click="setTask"
            :loading="btnLoading"
          >
            查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-checkbox-group v-model="checkedType">
            <div v-for="(item, i) in interfaceData" :key="i">
              <el-checkbox :label="item.name">
                <span>{{ item.display_name }}</span>
                <span class="el-icon-coin" style="margin-left: 10px">
                  {{ item.price }}
                </span>
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div class="left-log" :style="{ height: logComHeight + 'px' }">
        <logCom
          :logList="logList"
          :title="'近十条' + routerName + '查询记录'"
          @selectLog="selectLog"
        />
      </div>
    </div>
    <div class="phone-right">
      <div v-if="loading" class="right-title">
        正在搜索
        <span>{{ value || selectValue }}</span>
        的相关数据
      </div>
      <div v-else class="right-title">查询</div>
      <div class="right-content">
        <!-- 泄露库1   -->

        <local_social_engine
          v-if="checkedTypeInclude.indexOf('local_social_engine') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.local_social_engine"
        />
        <!-- 泄露库2 -->
        <leak_lookup_api
          v-if="checkedTypeInclude.indexOf('leak_lookup_api') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.leak_lookup_api"
        />

        <!-- 泄露库3   -->
        <dehashed
          v-if="checkedTypeInclude.indexOf('dehashed') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.dehashed"
        />
        <!-- 密码库1 -->
        <leakpeek
          v-if="checkedTypeInclude.indexOf('leakpeek') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.leakpeek"
        />
        <!-- 密码库2 -->
        <pwndb
          v-if="checkedTypeInclude.indexOf('pwndb') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.pwndb"
        />
        <!-- 密码库3 -->
        <snus_base
          v-if="checkedTypeInclude.indexOf('snus_base') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.snus_base"
        />
        <!-- 密码库4 -->
        <we_leak_info
          v-if="checkedTypeInclude.indexOf('we_leak_info') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.we_leak_info"
        />
        <!-- 历史网路足迹  -->
        <user_search
          v-if="checkedTypeInclude.indexOf('user_search') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.user_search"
        />
        <!-- 人员信息库    -->
        <people_data_labs
          v-if="checkedTypeInclude.indexOf('people_data_labs') > -1"
          :socketLoading="socketLoading"
          :tableList="listObj.people_data_labs"
        />

        <!-- 实时数据 -->
        <div>
          <div class="right-table-title real-time-data">
            <div>
              <img src="@/assets/imgs/i10.png" alt="" />
              <span class="s-1">实时数据</span>
              <span class="s-2" v-if="loading">
                共有
                <span class="s-2-1">{{ network_footprint.length }}</span>
                条，
                <span v-if="!socketLoading">查询已完成</span>
                <span v-else>查询进行中...</span>
              </span>
            </div>
            <div>
              <el-popover placement="left" trigger="hover">
                <div class="more-img-content">
                  <div
                    class="more-img-text"
                    :class="categoryKey == '全部' ? 'more-img-text-top' : ''"
                    @click="filterCategory('全部')"
                  >
                    全部
                  </div>
                  <div>
                    <span
                      @click="filterCategory(item)"
                      v-for="(item, i) in category"
                      :key="i"
                      class="more-img-text"
                      :class="categoryKey == item ? 'more-img-text-top' : ''"
                    >
                      {{ item }}
                    </span>
                  </div>
                </div>
                <span slot="reference">
                  <img class="more-img" src="@/assets/imgs/i7.png" alt="" />
                </span>
              </el-popover>
            </div>
          </div>
          <div
            class="real-data-content"
            :class="search_uid ? 'content-height-id' : 'content-height'"
          >
            <div
              class="real-data-content-child"
              v-if="network_footprint.length > 0"
            >
              <div
                class="real-item"
                v-for="(item, i) in network_footprint"
                :key="i"
              >
                <div class="real-item-left">
                  <a :href="item.website_index" target="_blank">
                    <img :src="item.website_logo" alt="" />
                    <div>{{ item.website_name }}</div>
                  </a>
                </div>
                <div class="real-item-right">
                  <div>
                    <label>是否注册：</label>
                    <span>{{ item.regist == true ? '已注册' : '未注册' }}</span>
                  </div>
                  <div v-for="(val, key) in displayNameArr" :key="key">
                    <div v-if="item[key]">
                      <label>{{ val }}：</label>
                      <span>{{ item[key] }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="real-item-industry"
                  v-if="Array.isArray(item.website_category)"
                >
                  <span v-for="(item, i) in item.website_category" :key="i">
                    {{ item }}
                  </span>
                </div>
              </div>
            </div>
            <no-data v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tableComponent from '../components/TableCom.vue'
import logCom from '../components/LogCom.vue'
import local_social_engine from './tableComponents/local_social_engine.vue' //泄露库1
import leak_lookup_api from './tableComponents/leak_lookup_api.vue' //泄露库2
import dehashed from './tableComponents/dehashed.vue' //泄露库3
import leakpeek from './tableComponents/leakpeek.vue' //密码库1
import pwndb from './tableComponents/pwndb.vue' //密码库2
import snus_base from './tableComponents/snus_base.vue' //密码库3
import we_leak_info from './tableComponents/we_leak_info.vue' //密码库4
import user_search from './tableComponents/user_search.vue' //历史网路足迹
import people_data_labs from './tableComponents/people_data_labs.vue' //人员信息库
import { displayName } from '@/api/displayName.js'

import { addTask } from '@/api/global'
import { io } from 'socket.io-client'
import { logList } from '@/api/history'

export default {
  components: {
    tableComponent,
    logCom,
    local_social_engine,
    leak_lookup_api,
    dehashed,
    leakpeek,
    pwndb,
    snus_base,
    we_leak_info,
    user_search,
    people_data_labs,
  },

  data() {
    return {
      displayNameArr: displayName,
      //
      interfaceData: [],
      logComHeight: 0,
      checkedType: [],
      checkedTypeInclude: [],
      value: '',
      logList: [], //查询记录
      websocketObj: null,
      search_uid: null,
      routerPath: null,
      routerName: null,
      loading: false,
      socketLoading: true,
      realTimeList: [],
      btnLoading: false,

      listObj: {
        local_social_engine: [],
        leak_lookup_api: [],
        dehashed: [],
        leakpeek: [],
        pwndb: [],
        snus_base: [],
        we_leak_info: [],
        user_search: [],
        people_data_labs: [],
        network_footprint: [],
      },
      categoryKey: '全部',
      selectValue: null,
      timer: null,
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    interfaceList() {
      if (this.$store.state.user.interface) {
        return this.$store.state.user.interface
      } else {
        return []
      }
    },
    storeToken() {
      return this.$store.state.user.token
    },
    info() {
      return this.$store.state.user.userInfo
    },
    category() {
      let arr = []
      if (this.listObj.network_footprint.length > 0) {
        this.listObj.network_footprint.forEach(item => {
          if (
            Array.isArray(item.website_category) &&
            item.website_category.length > 0
          ) {
            item.website_category.forEach(ele => {
              if (arr.indexOf(ele) == -1) {
                arr.push(ele)
              }
            })
          }
        })
      }
      return arr
    },
    network_footprint() {
      return this.listObj.network_footprint.filter(item => {
        if (item.website_category.indexOf(this.categoryKey) > -1) {
          return item
        } else if (this.categoryKey == '全部') {
          return item
        }
      })
    },
  },
  created() {
    this.pageType()
    this.getlogList() //历史记录
    // console.log(
    //   process.env.VUE_APP_BASE_API_WS + process.env.VUE_APP_SOCKETIO_PATH
    // )
  },
  mounted() {
    let height = document.getElementById('form-box').offsetHeight
    if (typeof height == 'number') {
      this.logComHeight = this.windowsHeight - height - 170
    }
  },
  destroyed() {
    clearTimeout(this.timer)
    this.websocketObj && this.websocketObj.disconnect()
  },
  methods: {
    // 筛选
    filterCategory(name) {
      this.categoryKey = name
    },
    pageType() {
      let path = this.$route.path
      if (path == '/phone/page') {
        this.routerPath = 'phone'
        this.routerName = '手机号'
      } else if (path == '/email/page') {
        this.routerPath = 'email'
        this.routerName = '邮箱'
      } else if (path == '/user/name') {
        this.routerPath = 'uname'
        this.routerName = '用户名'
      } else if (path == '/nick/name') {
        this.routerPath = 'nickname'
        this.routerName = '昵称'
      }

      this.interfaceData = this.interfaceList[this.routerPath]

      this.checkedType = this.interfaceList[this.routerPath].map(item => {
        return item.name
      })
    },
    //添加任务 并获取id
    setTask() {
      this.categoryKey = '全部'
      this.listObj.local_social_engine = []
      this.listObj.leak_lookup_api = []
      this.listObj.dehashed = []
      this.listObj.leakpeek = []
      this.listObj.pwndb = []
      this.listObj.snus_base = []
      this.listObj.we_leak_info = []
      this.listObj.user_search = []
      this.listObj.people_data_labs = []
      this.listObj.network_footprint = []
      this.checkedTypeInclude = []
      this.loading = false

      if (this.value.trim() == '') {
        this.$message({
          type: 'warning',
          message: `请输入${this.routerName}！`,
        })
        return
      }
      if (this.routerPath == 'phone') {
        var validateReg = /^[1-9]{1}\d{10}$/
        if (!validateReg.test(this.value)) {
          this.$message({
            type: 'warning',
            message: `请输入正确的手机号！`,
          })
          return
        }
      } else if (this.routerPath == 'email') {
        var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (!emailRegExp.test(this.value)) {
          this.$message({
            type: 'warning',
            message: `请输入正确的邮箱！`,
          })
          return
        }
      }

      if (this.checkedType.length == 0) {
        this.$message({
          type: 'warning',
          message: `请选择查询模块！`,
        })
        return
      }
      this.btnLoading = true
      this.loading = true
      this.checkedTypeInclude = [].concat(this.checkedType)
      let obj = {
        target_account: this.value,
        target_type: this.routerPath,
        interface: this.checkedType,
      }
      addTask(obj)
        .then(res => {
          let { status } = res
          if (status == 200) {
            if (res.data && res.data.data) {
              this.search_uid = res.data.data.search_uid
              this.$message({
                type: 'success',
                message: '任务提交成功!正在获取数据！',
              })

              this.initWebSocket()
            } else {
              this.loading = false
              this.checkedTypeInclude = []
              this.$message({
                type: 'error',
                message: res.data.message,
              })
            }
          }
          this.btnLoading = false
        })
        .catch(() => {
          this.loading = false
          this.checkedTypeInclude = []
          this.btnLoading = false
          this.$message({
            type: 'error',
            message: `查询失败！`,
          })
        })
    },
    selectLog(row) {
      this.checkedTypeInclude = []
      this.categoryKey = '全部'
      this.listObj.local_social_engine = []
      this.listObj.leak_lookup_api = []
      this.listObj.dehashed = []
      this.listObj.leakpeek = []
      this.listObj.pwndb = []
      this.listObj.snus_base = []
      this.listObj.we_leak_info = []
      this.listObj.user_search = []
      this.listObj.people_data_labs = []
      this.listObj.network_footprint = []
      this.checkedTypeInclude = row.interface
      //
      this.search_uid = row.search_uid
      this.loading = true
      this.selectValue = row.target_account
      this.initWebSocket()
    },
    // 历史记录
    getlogList() {
      let obj = {
        page_index: 1,
        page_size: 10,

        target_type: this.routerPath,
      }
      logList(obj).then(res => {
        this.logList = res.data.data.task_list
      })
    },
    initWebSocket() {
      //初始化weosocket
      // const wsuri = 'ws://192.168.3.200:5000/ws/v1/task/get_result'
      this.socketLoading = true
      this.websocketObj = io(process.env.VUE_APP_SOCKETIO_BASE, {
        auth: {
          token: this.storeToken,
          search_uid: this.search_uid,
        },
        path: process.env.VUE_APP_SOCKETIO_PATH,
        transports: ['websocket'],
      })
      this.websocketObj.on('json', res => {
        const { status, message, data } = res
        if (status === 'success' && message === '获取成功') {
          if (typeof data == 'object') {
            for (let i in data) {
              // this.checkedTypeInclude.push(i) //一会去掉
              this.listObj[i] = this.listObj[i].concat(data[i])
            }
          }
        }

        if (status === 'success' && message === '全部获取完成') {
          this.socketLoading = false
        }

        if (status === 'success' && message === '获取进行中的数据') {
          this.timer = setTimeout(
            () =>
              this.websocketObj.emit(
                'get_result',
                JSON.stringify({ next: data })
              ),
            3000
          )
        }
      })

      this.websocketObj.on('message', res => {
        console.log('接收到的message')
        console.log(res)
      })
      this.websocketObj.emit(
        'get_result',
        JSON.stringify({
          next: null,
        })
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.phone-page {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  .phone-left {
    width: 259px;
    height: 100%;
    background: #ffffff;
    border: 0px solid #000000;
    box-shadow: 0px 0px 10px 0px rgba(124, 124, 124, 0.2);
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    .left-log {
      width: 100%;
      // height: calc(100% - 270px);
      overflow: hidden;
      margin-top: 30px;
    }
  }
  .phone-right {
    width: calc(100% - 270px);
    height: 100%;

    .right-title {
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      color: #1a1a1b;
      line-height: 40px;
      border-bottom: 1px solid #cbc8da;
      white-space: nowrap;

      span {
        font-size: 22px;
        font-family: Constantia;
        font-weight: 400;
        color: #409eff;
        line-height: 40px;
      }
    }
    .right-content {
      height: calc(100% - 40px);
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      margin-top: 10px;
      .right-table-title {
        width: 100%;
        height: 48px;
        background: white;
        box-shadow: 0px 2px 7px #d8d9d9;
        margin-bottom: 7px;
        line-height: 48px;
        white-space: nowrap;
        box-shadow: 0 1px 3px 0 #d8d9d9;
        border: 1px solid #e5e5e5;

        img {
          width: 32px;
          height: 32px;
          vertical-align: middle;
          margin-left: 15px;
        }
        .s-1 {
          font-size: 16px;
          margin-left: 15px;
          font-weight: 400;
          color: #355383;
        }
        .s-2 {
          font-size: 12px;
          margin-left: 40px;
          font-weight: 400;
          color: #355383;
          .s-2-1 {
            font-size: 20px;

            font-weight: 600;
            color: #409eff;
          }
        }
      }
      .real-time-data {
        display: flex;
        justify-content: space-between;
        .more-img {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-right: 10px;
        }
      }

      // .pagination-box {
      //   background: white;
      //   padding: 5px 0;
      //   overflow: hidden;
      // }

      .real-data-content {
        padding: 15px 15px 5px 15px;
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        background: #ffffff;
        position: relative;
        min-height: 50px;
        box-shadow: 0 1px 3px 0 #d8d9d9;
        border: 1px solid #e5e5e5;
        .real-data-content-child {
          width: calc(100% + 20px);

          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;

          .real-item {
            overflow: hidden;
            width: calc(100% / 3 - 20px);
            margin-right: 20px;
            margin-bottom: 10px;
            padding: 10px 0;
            // height: 125px;
            background: #ffffff;
            box-shadow: 0px 1px 6px 0px rgba(105, 105, 105, 0.35);
            border-radius: 5px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            .real-item-left {
              align-self: center;
              text-align: center;
              margin-left: 15px;
              img {
                width: 60px;
                height: 60px;
                object-fit: cover;
              }
              div {
                font-size: 14px;
                font-weight: bold;
                color: #000000;
                line-height: 24px;
              }
            }
            .real-item-right {
              align-self: center;
              margin-left: 34px;
              > div {
                // white-space: nowrap;
                label {
                  font-size: 14px;
                  line-height: 24px;
                  font-weight: 300;
                  color: #2a2a2a;
                }
                span {
                  font-size: 14px;
                  line-height: 24px;
                  font-weight: 300;
                  color: #ff0000;
                }
              }
            }
            .real-item-industry {
              position: absolute;
              top: 15px;
              right: 10px;
              height: calc(100% - 30px);
              width: 50px;
              overflow: hidden;
              span {
                font-size: 12px;
                background: #efefef;
                border-radius: 5px;
                font-weight: 300;
                color: #000000;
                line-height: 24px;
                padding: 2px 5px;
                margin: 5px;
              }
            }
            .real-item-industry-more {
              position: absolute;
              top: 15px;
              right: 5px;
              height: calc(100% - 30px);
              width: 100px;
              overflow: auto;
              span {
                font-size: 12px;
                background: #efefef;
                border-radius: 5px;
                font-weight: 300;
                color: #000000;
                line-height: 24px;
                padding: 2px 5px;
                margin: 5px;
              }
            }
          }
        }
      }
      .content-height {
        height: calc(100vh - 205px) !important;
        .content-height-padding {
          padding: 40px;
        }
      }
      .content-height-id {
        min-height: 100px;
        margin-bottom: 15px;
      }
    }
  }
}
.more-img-content {
  padding: 10px 5px;
  .more-img-text {
    font-size: 14px;
    font-weight: 300;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .more-img-text-top {
    color: #0362ff;
    font-weight: 600;
  }
}
</style>
